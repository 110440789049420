import React from 'react';
import { createRoot } from 'react-dom/client';
import 'wwtc-sdk/dist/index.css';
// colocar de primero app y luego las clases que van a hacer override de las clases css
// OJO PARA LA DOCUMENTACIÓN
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
