import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, pubnub } from 'wwtc-sdk';
import './login.css';
import './common/button.css';

function Login({ languages }) {
    const history = useHistory();
    const [form, setForm] = useState({
        language: null,
        displayName: null,
        email: null,
    });
    const [isModeratorPresent, setIsModeratorPresent] = useState(false);

    useEffect(() => {
        pubnub.addListener({
            presence: function (event) {
                var action = event.action;
                var state = event.state;

                if (action === 'state-change' && state.role === 'moderator') {
                    setIsModeratorPresent(true);
                }

                if (action === 'leave' && state.role === 'moderator') {
                    setIsModeratorPresent(false);
                }
            },
        });

        pubnub.subscribe({
            channels: [window.room],
            withPresence: true,
        });

        pubnub
            .hereNow({
                channels: [window.room],
                includeState: true,
            })
            .then(async (res) => {
                // If moderator is present in the room
                // allow to access to invited user
                const participantsInChannel = res.channels[window.room].occupants;

                const isModerator = participantsInChannel.find(
                    (part) => part.state && part.state.role === 'moderator',
                );

                if (isModerator !== undefined) {
                    setIsModeratorPresent(true);
                }
            });
    }, []);

    // useEffect(() => {
    //     if (!!waitingRoom) {
    //         pubnub.addListener({
    //             signal: function (s) {
    //                 if (s.message.search('join_room') >= 0) {
    //                     const participantId = s.message.split('join_room-')[1];
    //                     if (participantId === myPubnubId) {
    //                         joinRoom();
    //                     }
    //                 }
    //             },
    //         });
    //     }
    // }, [waitingRoom]);

    const handleLanguage = (language) => {
        setForm({ ...form, language: language });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const { email, displayName } = form;

        if (validateLogin()) {
            try {
                const isEmailEmpty = !email || email === '';
                const displayFullFormat = !!displayName ? `+${displayName}` : '';
                const stringConcat = isEmailEmpty ? displayName : `${email}${displayFullFormat}`;
                var raw = JSON.stringify({ email: `${stringConcat}` });

                await fetch('https://6aumwy0sk7.execute-api.us-east-1.amazonaws.com/prod/email', {
                    method: 'POST',
                    'Content-Type': 'application/json',
                    body: raw,
                    redirect: 'follow',
                    mode: 'no-cors',
                });

                joinRoom();
            } catch (e) {
                console.log(e);
                alert('Error fetching Email endpoint');
            }
        } else {
            alert('Please fill inputs to continue');
        }
    };

    const joinRoom = () => {
        const { displayName, language } = form;

        history.push(`/app`, {
            userName: displayName,
            language: language.value,
            gender: 'male',
            roomName: window.room,
            apiToken: window.apiToken,
            logoHeader: window.logoHeader,
        });
    };

    const validateLogin = () => {
        let valid = true;

        const isLanguageEmpty = !form['language'] || form['language'] === '';
        const isEmailEmpty = !form['email'] || form['email'] === '';
        const isDisplayNameEmpty = !form['displayName'] || form['displayName'] === '';

        if (!!isLanguageEmpty) valid = false;

        if (!!isDisplayNameEmpty && !!isEmailEmpty) valid = false;

        return valid;
    };

    return (
        <div className="wrapper-login">
            <div className="wrapper-content">
                <div className="div-tests-button"></div>
                <div className="wrapper-login-modal">
                    <div className="wrapper-logo">
                        <img src={`${process.env.PUBLIC_URL}/${window.logoLogin}`} alt="WWTC" />
                    </div>
                    <form className="form-modal" onSubmit={handleLogin}>
                        <div className="title-welcome">
                            <h2>Welcome</h2>
                        </div>
                        <div className="wrapper-login-form">
                            <div className="login-inputs">
                                <Select
                                    handleChange={handleLanguage}
                                    placeholder="Select language"
                                    data={languages}
                                    value={form.language || ''}
                                />
                            </div>
                            <div className="login-inputs">
                                <input
                                    className="Input"
                                    type="email"
                                    placeholder="Email"
                                    value={form.email || ''}
                                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                                    style={{ width: 'calc(100% - 4px)' }}
                                />
                            </div>
                            <div className="login-inputs">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: 8,
                                    }}
                                >
                                    <span>or</span>
                                </div>
                                <input
                                    className="Input"
                                    type="text"
                                    placeholder="Display Name"
                                    value={form.displayName || ''}
                                    onChange={(e) =>
                                        setForm({ ...form, displayName: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="wrapper-button-actions">
                            <button
                                onClick={handleLogin}
                                type="submit"
                                className={`Button ${
                                    isModeratorPresent ? 'Button-primary' : 'Button-gray'
                                } Button-block`}
                                disabled={!isModeratorPresent}
                            >
                                connect now
                            </button>
                        </div>
                        {!isModeratorPresent && (
                            <div className="error-message">
                                <p style={{ textAlign: 'center' }}>Moderator is not present</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
