import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { fetchLanguages } from 'wwtc-sdk';
import Main from './components/Main.js';
import Login from './components/Login.js';

function App() {
    const [state, setState] = React.useState({
        languages: [],
        status: 'loading',
    });

    React.useEffect(() => {
        fetchCompanyInfo().then((response) => {
            window.room = response.data.pubnubRoom;
            window.logoHeader = response.data.logoHeader;
            window.logoLogin = response.data.logoLogin;
            window.apiToken = response.data.apiToken;

            // set palette color
            if (!!response.data.theme) {
                setPaletteColor(response.data.theme);
            }

            fetchLanguages().then((languages) => {
                setState({
                    status: 'resolved',
                    languages,
                });
            });
        });
    }, []);

    /**
     * Set color palette to css variables :root (src/index.css)
     * @param {object} theme Color palette properties
     */
    const setPaletteColor = (theme) => {
        document.documentElement.style.setProperty(
            '--main-bg-button-primary',
            theme.bgColorLoginButton,
        );
        document.documentElement.style.setProperty(
            '--main-color-button-primary',
            theme.mainButtonTextColor,
        );
        document.documentElement.style.setProperty('--header-color-text', theme.headerTextColor);
        document.documentElement.style.setProperty('--gradient-primary', theme.gradientPrimary);
        document.documentElement.style.setProperty('--gradient-secondary', theme.gradientSecondary);
        document.documentElement.style.setProperty(
            '--component-header-option',
            theme.textHeaderComponents,
        );

        if (!!theme.primaryColor) {
            document.documentElement.style.setProperty('--main-primary', theme.primaryColor);
        }
    };

    const fetchCompanyInfo = async () => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}/manifest.json`);
            return response.json();
        } catch (e) {
            return new Error(e);
        }
    };

    if (state.status === 'loading') {
        return (
            <div className="loader-wrapper">
                <div className="text-center">
                    <h1 className="loader-title">Loading app...</h1>
                </div>
            </div>
        );
    }
    return (
        <Router basename="/italyhof">
            <Switch>
                <Route path="/app">
                    <Main />
                </Route>
                <Route path="/">
                    <Login languages={state.languages} />
                </Route>
            </Switch>
        </Router>
    );
}
export default App;
