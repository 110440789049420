import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Subtitles,
    pubnub,
    TextToSpeech,
    SpeechToText,
    MainWrapper,
    useStateValue,
} from 'wwtc-sdk';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    signedEndpoint,
} from '../constants/connection';
import { Download } from './svg/icons';
import buildSubtitlesFile from '../utils/subtitles';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const { userName, language, gender, roomName, apiToken, logoHeader } = history.location
        .state || {
        userName: '',
        language: '',
        gender: '',
        roomName: 'default', // default name
        apiToken: null,
        logoHeader: null,
    };
    const myPubnubId = pubnub.userId;

    useEffect(() => {
        if (userName === '') {
            history.push(`/`, {
                userName: userName,
                language: language,
                gender: gender,
            });
        }
        pubnub.addListener({
            signal: function (s) {
                if (s.message.search('kickout') >= 0) {
                    const participantId = s.message.split('kickout_participant-')[1];
                    if (participantId === myPubnubId) {
                        window.location.replace(window.location.origin);
                    }
                }
                if (s.message === 'clean_history_content') {
                    // clean subtitles content
                    document.getElementsByClassName('fa-trash-alt')[0].parentNode.click();
                }
                if (s.message === 'logout_admin') {
                    pubnub.unsubscribeAll();
                    window.location.replace(window.location.origin);
                }
            },
        });
        return () => {};
    }, []);

    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={roomName}
            userName={userName}
        >
            <Header logoHeader={logoHeader} pubnub={pubnub} />
            <WrapperComponents apiToken={apiToken} />
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const { logoHeader, pubnub, initName } = props;

    const handleLogout = () => {
        if (pubnub) {
            pubnub.unsubscribeAll();
            window.location.replace(window.location.origin);
        } else {
            window.location.replace(window.location.origin);
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${process.env.PUBLIC_URL}/${window.logoLogin}`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>The box 2.0</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperComponents(props) {
    // values from autologin
    const { apiToken, initName, initLanguage, initGender, conferenceName } = props;
    const [{ subtitles: subtitlesComponent }] = useStateValue();

    const downloadSubtitlesPDF = (subtitles) => {
        const languageBilingual = subtitlesComponent.sourceLanguage.value;
        const contentPDF = buildSubtitlesFile(subtitles, languageBilingual);

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                fontSize: 12,
            },
        };
        window.pdfMake.createPdf(docDefinition).download();
    };

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        try {
            let response = await fetch(endpoint.url.replace(/:sourceLanguage/g, sourceLanguage), {
                method: endpoint.method,
                redirect: 'follow',
                body: JSON.stringify({
                    text,
                }),
            });
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    return (
        <div className="wrapper-components">
            <Subtitles.SubtitlesUI
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage}
                initialVisible={true}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                speechRecognition={false}
                showInput={false}
                showButtonCloseComponent={false}
                ttsOption={true}
                channel={conferenceName}
                style={stylesWrapper.fullContainer}
                render={(subtitles) => (
                    <button className="a-element" onClick={() => downloadSubtitlesPDF(subtitles)}>
                        <Download />
                    </button>
                )}
            />
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={signedEndpoint(apiToken, endpointSpeechToText)}
            />
            <TextToSpeech.TextToSpeechUI
                initialVendor="readspeaker"
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={signedEndpoint(apiToken, endpointTextToSpeech)}
                fetchCustom={fetchTextToSpeechCustom}
            />
        </div>
    );
}

const stylesWrapper = {
    fullContainer: {
        width: 'calc(100% - 20px)',
        height: '100%',
        margin: '10px auto',
    },
};

export default Main;
